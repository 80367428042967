import React, { Component } from 'react';
import { connect } from 'react-redux';
import style from './sidebar.module.less';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import Auxiliary from 'util/Auxiliary';
import UserProfile from './UserProfile';
import { actions as conversationsActions } from 'appRedux/resources/messages/conversationResources';
import { actions as socialPostActions } from 'appRedux/resources/socialPostResources';
import { actions as pagesActions } from 'appRedux/resources/messages/pagesResources';

import { bindActionCreators } from 'redux';

import IntlMessages from '../../util/IntlMessages';
import { pusher } from 'appRedux/store';

import { NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from '../../constants/ThemeSetting';
import { CHANNELS, EVENTS } from '../../constants/Pusher';
import { USER_ROLES } from '../../constants/Role';

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {
  state = {
    openKeys: [],
    selectedMenuItem: 'dashboard',
    selectedSubMenuItem: ''
  };

  rootSubmenuKeys = ['dashboard', 'reviews', 'messages', 'reporting', 'feedback', 'settings', 'admin_panel'];

  async componentDidMount() {
    if (this.props.currentUser) {
      this.props.countConversation();
    }
    const selectedKey = this.props.pathname.substr(1);
    const selectedKeys = selectedKey.includes('/') ? selectedKey.split('/')[0] : selectedKey;
    const subSelectedMenuItemKey = selectedKey.includes('/') && selectedKey.split('/')[1];
    if (subSelectedMenuItemKey) {
      this.setState({
        selectedSubMenuItem: subSelectedMenuItemKey
      });
    }
    this.setState({
      openKeys: [selectedKeys],
      selectedMenuItem: selectedKeys
    });

    setTimeout(async () => {
      await this.getUnreadCounts();
      await this.fetchMessagesPages();
    }, 1000);

    let channel = pusher.subscribe(`localyser-channel-${CHANNELS.totalCount}-${this.props.currentUser?.client}`);

    channel.bind(EVENTS.count, async () => {
      await this.getUnreadCounts();
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.currentUser !== this.props.currentUser || prevProps.facebookData?.length !== this.props.facebookData?.length) {
      await this.getUnreadCounts();
    }
    const selectedKey = this.props.pathname.substr(1);
    const selectedKeys = selectedKey.includes('/') ? selectedKey.split('/')[0] : selectedKey;
    const subSelectedMenuItemKey = selectedKey.includes('/') && selectedKey.split('/')[1];
    if (this.state.selectedSubMenuItem !== subSelectedMenuItemKey) {
      this.setState({
        selectedSubMenuItem: subSelectedMenuItemKey
      });
    }
    if (this.state.selectedMenuItem !== selectedKeys) {
      this.setState({
        selectedMenuItem: selectedKeys,
        openKeys: [selectedKeys]
      });
    }
    if (!this.state.openKeys[0] && selectedKeys) {
      this.setState({
        openKeys: [selectedKeys]
      });
    }
  }

  componentWillUnmount() {
    pusher.unsubscribe(`localyser-channel-${CHANNELS.totalCount}-${this.props.currentUser?.client}`);
  }

  onOpenChange = selectedMenuItem => {
    const latestOpenKey = selectedMenuItem && selectedMenuItem.find(key => this.state.openKeys.indexOf(key) === -1);
    if (latestOpenKey && this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: selectedMenuItem });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  };

  getNoHeaderClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return 'gx-no-header-notifications';
    }
    return '';
  };
  getNavStyleSubMenuClass = navStyle => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return 'gx-no-header-submenu-popup';
    }
    return '';
  };
  getMenuItemStyle = key => {
    return this.state.selectedSubMenuItem === key ? style.selectedSubMenuItem : undefined;
  };
  getSubmenyStile = key =>
    this.state.selectedMenuItem.includes(key) || this.state.openKeys[0] === key ? style.submenuItemSelected : style.submenuItem;

  getUnreadCounts = async () => {
    const getUnreadTotalCountConversation = this.props.getUnreadTotalCountConversation;
    const getUnreadTotalCountSocialpost = this.props.getUnreadTotalCountSocialpost;

    await Promise.all([getUnreadTotalCountConversation(), getUnreadTotalCountSocialpost()]);
  };

  fetchMessagesPages = () => {
    this.props.initiateLoadingPages();
    return this.props.getMessagesPages();
  };

  render() {
    const { navStyle, currentUser, accessPages } = this.props;
    const { selectedMenuItem } = this.state;
    const partnerLikeCompany = JSON.parse(localStorage.getItem('partnerAsCompany') || '{}').userName;
    const ownerLikeCompany = JSON.parse(localStorage.getItem('ownerAsCompany') || '{}').userName;
    const partnerLikeUser = JSON.parse(localStorage.getItem('partnerAsUser') || '{}').userName;
    const menuItemNotVisible =
      currentUser && (currentUser.role !== 'Partner' || (currentUser.role === 'Partner' && (partnerLikeUser || partnerLikeCompany)));
    const locationMenuItemTitle = `${currentUser?.locationsTitle?.toLowerCase()}s`;
    const unreadCount = this.props.unreadMessages + this.props.unreadComments;
    const anyPageAccess = accessPages && accessPages.length > 0;

    const isListingManagement = (currentUser) => {
      return currentUser?.features?.includes('Listing Management');
    };
    const hasAccess = (user) => {
      if (!user) return false;
      const allowedRoles = ['Master Admin', 'Manager', 'Owner', 'Partner'];
      return allowedRoles.includes(user.role);
    };
    // const isReviewsAndRatingMenuItemVisible =
    //   currentUser && (currentUser.companyName === 'AWJ Investments L.L.C.' || currentUser.companyName === 'Gourmet Gulf'|| currentUser.companyName === "Americana") ? true : false;
    return (
      <Auxiliary>
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications
            ${this.getNoHeaderClass(navStyle)}`}
          >
            <UserProfile />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            openKeys={this.state.openKeys}
            selectedKeys={[selectedMenuItem]}
            onOpenChange={this.onOpenChange}
            className={style.menuStyle}
          >
            {currentUser && menuItemNotVisible && (
              <Menu.Item
                key="dashboard"
                onClick={() => this.onOpenChange(null)}
                className={selectedMenuItem.includes('dashboard') ? style.menuItemSelected : style.menuItem}
              >
                <Link to="/dashboard">
                  <i className="icon icon-dasbhoard" />
                  <IntlMessages id="sidebar.dashboard" />
                </Link>
              </Menu.Item>
            )}
            {currentUser && menuItemNotVisible && (
              <Menu.Item
                key="reviews"
                onClick={() => this.onOpenChange(null)}
                className={selectedMenuItem.includes('reviews') ? style.menuItemSelected : style.menuItem}
              >
                <Link to="/reviews">
                  <i className="icon icon-chat-bubble" />
                  <IntlMessages id="sidebar.reviews" />
                </Link>
              </Menu.Item>
            )}
            {currentUser && menuItemNotVisible && currentUser.features && currentUser.features.includes('Messaging') && (
              <SubMenu
                className={style.messagesSubmenu}
                key="messages"
                title={
                  <Link to="/messages" className={this.getSubmenyStile('messages')}>
                    <i className="icon icon-feedback" />
                    <IntlMessages id="sidebar.messages" />
                    {anyPageAccess && unreadCount ? <div className={style.badge}>{unreadCount > 99 ? '99+' : unreadCount}</div> : null}
                    <div className={`${style.newBadge} ${unreadCount ? style.messageNewBadge : ''}`}>New</div>
                  </Link>
                }
              >
                {anyPageAccess && (
                  <Menu.Item key="messagesAnalytics">
                    <Link to="/messages/analytics">
                      <span className={this.getMenuItemStyle('analytics')}>
                        <IntlMessages id="sidebar.messages.analytics" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {anyPageAccess && (
                  <Menu.Item key="pages">
                    <Link to="/messages/pages">
                      <span className={this.getMenuItemStyle('pages')}>
                        <IntlMessages id="sidebar.messages.pages" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {currentUser && menuItemNotVisible && (
              <SubMenu
                className={style.submenuStyle}
                key="reporting"
                title={
                  <span className={this.getSubmenyStile('reporting')}>
                    <i className="icon icon-chart" />
                    <IntlMessages id="sidebar.reporting" />
                  </span>
                }
              >
                <Menu.Item key="overview">
                  <Link to="/reporting/overview">
                    <span className={this.getMenuItemStyle('overview')}>
                      <IntlMessages id="sidebar.reporting.overview" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="trends">
                  <Link to="/reporting/trends">
                    <span className={this.getMenuItemStyle('trends')}>
                      <IntlMessages id="sidebar.reporting.trends" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ranking_responses">
                  <Link to="/reporting/ranking_responses">
                    <span className={this.getMenuItemStyle('ranking_responses')}>
                      <IntlMessages id="sidebar.reporting.rankingResponses" />
                    </span>
                    <div className={`${style.newBadge} ${style.newBadgeLeft}`}>New</div>
                  </Link>
                </Menu.Item>
                {/* <Menu.Item key="ranking">
                  <Link to="/reporting/ranking">
                    <span className={this.getMenuItemStyle('ranking')}>
                      <IntlMessages id="sidebar.reporting.ranking" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="responses">
                  <Link to="/reporting/responses">
                    <span className={this.getMenuItemStyle('responses')}>
                      <IntlMessages id="sidebar.reporting.responses" />
                    </span>
                  </Link>
                </Menu.Item> */}
                {/* {isReviewsAndRatingMenuItemVisible && ( */}
                <Menu.Item key="ratingsAndReviews">
                  <Link to="/reporting/ratings_reviews">
                    <span className={this.getMenuItemStyle('ratings_reviews')}>
                      <IntlMessages id="sidebar.reporting.ratingsAndReviews" />
                    </span>
                  </Link>
                </Menu.Item>
                {/* )} */}
                {currentUser && menuItemNotVisible && isListingManagement(currentUser) && (
                  <Menu.Item
                    key="listingManagement"
                    className={selectedMenuItem.includes('listingManagement') ? style.menuItemSelected : style.menuItem}
                  >
                    <Link to="/listing_management">
                      <IntlMessages id="sidebar.listingManagement" />
                    </Link>
                  </Menu.Item>
                )}
                {((currentUser.features && currentUser.features.includes('Competitor Benchmarking')) || currentUser.role === 'Owner') && (
                  <Menu.Item key="competitorsReport">
                    <Link to="/reporting/competitors">
                      <span className={this.getMenuItemStyle('competitors')}>
                        <IntlMessages id="sidebar.reporting.competitors" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {currentUser.features && currentUser.features.includes('Insights Report') && (
                  <Menu.Item key="insights">
                    <Link to="/reporting/insights">
                      <span className={this.getMenuItemStyle('insights')}>
                        <IntlMessages id="sidebar.reporting.insights" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {currentUser &&
              menuItemNotVisible &&
              currentUser.features &&
              currentUser.features.includes('Feedback') && ( //package model
                <SubMenu
                  // className={this.getNavStyleSubMenuClass(navStyle)}
                  key="feedback"
                  title={
                    <span className={this.getSubmenyStile('feedback')}>
                      <i className="icon icon-feedback" />
                      <IntlMessages id="sidebar.feedback" />
                    </span>
                  }
                >
                  <Menu.Item key="landingPage">
                    <Link to="/feedback/landingPage">
                      <span className={this.getMenuItemStyle('landingPage')}>
                        <IntlMessages id="sidebar.feedback.landingPage" />
                      </span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="analytics">
                    <Link to="/feedback/analytics">
                      <span className={this.getMenuItemStyle('analytics')}>
                        <IntlMessages id="sidebar.feedback.analytics" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )}
            {currentUser &&
              menuItemNotVisible &&
              hasAccess(currentUser) ? (
              <SubMenu
                // className={this.getNavStyleSubMenuClass(navStyle)}
                className={this.state.selectedMenuItem.includes('settings') ? style.submenuStyle : style.submenuStyleNotSelected}
                key="settings"
                title={
                  <span className={this.getSubmenyStile('settings')}>
                    <i className="icon icon-setting" />
                    <IntlMessages id="sidebar.settings" />
                  </span>
                }
              >
                <Menu.Item key="notifications">
                  <Link to="/settings/notifications">
                    <span className={this.getMenuItemStyle('notifications')}>
                      <IntlMessages id="sidebar.settings.notifications" />
                    </span>
                  </Link>
                </Menu.Item>
                {((currentUser && currentUser.features && currentUser.features.includes('Competitor Benchmarking')) ||
                  (currentUser && currentUser.role === 'Owner')) && (
                    <Menu.Item key="competitors">
                      <Link to="/settings/competitors">
                        <span className={this.getMenuItemStyle('competitors')}>
                          <IntlMessages id="sidebar.settings.competitors" />
                        </span>
                      </Link>
                    </Menu.Item>
                  )}
                <Menu.Item key="locations">
                  <Link to="/settings/locations">
                    <span className={this.getMenuItemStyle('locations')}>
                      <IntlMessages id={`sidebar.settings.${locationMenuItemTitle}`} />
                    </span>
                  </Link>
                </Menu.Item>
                {currentUser && currentUser.features && currentUser.features.includes('Review Categorization') && (
                  <Menu.Item key="response">
                    <Link to="/settings/response">
                      <span className={this.getMenuItemStyle('response')}>
                        <IntlMessages id="sidebar.settings.response" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item key="team_access">
                  <Link to="/settings/team_access">
                    <span className={this.getMenuItemStyle('team_access')}>
                      <IntlMessages id="sidebar.settings.team_access" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="other">
                  <Link
                    onClick={() => localStorage.removeItem('ownerAsClient')}
                    to={
                      currentUser && currentUser.features && currentUser.features.includes('Review Categorization')
                        ? '/settings/other/reviewCategories'
                        : '/settings/other/thirdPartyLogin'
                    }
                  >
                    <span className={this.getMenuItemStyle('other')}>
                      <IntlMessages id="sidebar.settings.other" />
                    </span>
                  </Link>
                </Menu.Item>
              </SubMenu>
            ) : (
              currentUser &&
              menuItemNotVisible && (
                <SubMenu
                  // className={this.getNavStyleSubMenuClass(navStyle)}

                  className={this.state.selectedMenuItem.includes('settings') ? style.submenuStyle : style.submenuStyleNotSelected}
                  key="settings"
                  title={
                    <span className={this.getSubmenyStile('settings')}>
                      <i className="icon icon-setting" />
                      <IntlMessages id="sidebar.settings" />
                    </span>
                  }
                >
                  <Menu.Item key="notifications">
                    <Link to="/settings/notifications">
                      <span className={this.getMenuItemStyle('notifications')}>
                        <IntlMessages id="sidebar.settings.notifications" />
                      </span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              )
            )}
            {currentUser && menuItemNotVisible && <div className={style.menuDivider} />}
            {currentUser && ((currentUser.role === 'Owner' && !ownerLikeCompany) || (currentUser.role === 'Partner' && !partnerLikeCompany)) && (
              <SubMenu
                // className={this.getNavStyleSubMenuClass(navStyle)}
                key="admin_panel"
                title={
                  <span className={this.getSubmenyStile('admin_panel')}>
                    <i className="icon icon-wall" />
                    <IntlMessages id="sidebar.admin_panel" />
                  </span>
                }
              >
                <Menu.Item key="companies">
                  <Link to="/admin_panel/companies">
                    <span className={this.getMenuItemStyle('companies')}>
                      <IntlMessages id="sidebar.admin_panel.companies" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="adminUsers">
                  <Link to="/admin_panel/admin_users">
                    <span className={this.getMenuItemStyle('admin_users')}>
                      <IntlMessages id="sidebar.admin_panel.adminUsers" />
                    </span>
                  </Link>
                </Menu.Item>

                {menuItemNotVisible && (
                  <Menu.Item key="partners">
                    <Link to="/admin_panel/partners">
                      <span className={this.getMenuItemStyle('partners')}>
                        <IntlMessages id="sidebar.admin_panel.partners" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {currentUser.role === USER_ROLES.Owner ? (
                  <Menu.Item key="csdashboard">
                    <Link to="/admin_panel/csdashboard/overview">
                      <span className={this.getMenuItemStyle('csdashboard')}>
                        <IntlMessages id="sidebar.admin_panel.csdashboard" />
                      </span>
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {currentUser.role === USER_ROLES.Owner ? (
                  <Menu.Item key="systemhealth">
                    <Link to="/admin_panel/systemhealth">
                      <span className={this.getMenuItemStyle('systemhealth')}>
                        <IntlMessages id="sidebar.admin_panel.systemhealth" />
                      </span>
                    </Link>
                  </Menu.Item>
                ) : (
                  ''
                )}
                {/*
                <Menu.Item key="start_parser">
                <Button type="danger" onClick={()=>alert("force start the parser")} className="gx-mb-0 gx-app-login-button" htmlType="submit">
                    <IntlMessages id="sidebar.startParser" />
                </Button>
                </Menu.Item>
                */}
              </SubMenu>
            )}
          </Menu>
        </div>
      </Auxiliary >
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = state => {
  const { navStyle, themeType, locale, pathname } = state.settings;
  const facebookData = state.otherLogins.otherLoginsInfo.items[0]?.facebook;
  const currentUser = state.team_users.currentUser.items[0];
  const unreadMessages = state.conversation.unreadTotalCount;
  const unreadComments = state.socialPost.unreadTotalCount;
  const accessPages = state.pages.items;

  return {
    navStyle,
    themeType,
    locale,
    pathname,
    currentUser,
    unreadMessages,
    unreadComments,
    facebookData,
    accessPages
  };
};
const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(conversationsActions, dispatch),
  ...bindActionCreators(socialPostActions, dispatch),
  ...bindActionCreators(pagesActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContent);
